


import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import asyncComponent from './asyncComponent';
import LanguageContext from './component/LanguageContext';
import Language from './asset/Language'

let offset_width = document.body.offsetWidth;
if (offset_width < 960) {
  window.location.href='http://mobile.mppoint.us/#/'
}


const Desktop = asyncComponent(() => import('./views/Desktop'));
// const Home = (props) => {

// }

function App() {
  return (
    <LanguageContext.Provider value={Language}>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/:id?" exact component={Desktop}></Route>
            {/* <Route path="/desktop" exact  component={Desktop}></Route> */}
          </Switch>
        </div>
      </Router>
    </LanguageContext.Provider>
  );
}

export default App;
